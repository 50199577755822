import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../assets/css/app/page.processo.css';
import Swal from 'sweetalert2';
import { QRCodeCanvas } from 'qrcode.react';
import {
  GlobalContext,
  PKG,
  CONF,
  SCR,
  NavBar,
  API,
  END,
} from '../../config/imports';

export default function SimulacaoReport() {
  const {
    checkLogin,
    navigate,
    emailArchive,
    setEmailArchive,
    emailccArchive,
    setEmailccArchive,
  } = useContext(GlobalContext);
  const { theme, changeTheme } = useContext(PKG.PackageContext);
  const savedTheme = theme;
  const { numberFormat } = SCR.Helpers;
  const { codigo } = useParams();

  const [processo, setProcesso] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const canvasRef = useRef();

  const dataBR = (stringDate) => {
    const regexYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/;
    if (regexYYYYMMDD.test(stringDate)) {
      let parts = stringDate.split('-');
      return (
        parts[2] +
        '/' +
        parts[1].toString().padStart(2, '0') +
        '/' +
        parts[0].toString().padStart(2, '0')
      );
    }
    return stringDate;
  };

  const dateFormat = (stringDate) => {
    let formated = stringDate;
    if (stringDate) {
      if (stringDate.includes('T')) {
        formated = stringDate.split('T')[0];
      }
      return dataBR(formated);
    }
    return formated;
  };

  async function handlePDF(mail) {
    setLoading(true);
    document.documentElement.className = 'light';
    const filename = `Relatório de Processo - ${processo.NOME}.pdf`;
    const emailBody = `
      <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>Olá, segue em anexo o relatório do processo de ${processo.NOME}.</p>
      <br><br>
      <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>Atenciosamente,</p>
      <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>${CONF.setup.name}</p>
    `;
    const reportBlob = await SCR.PDF.getBlob('downloadProcessoReport', 'blob');

    await SCR.PDF.sendPdfToMail(
      reportBlob,
      filename,
      mail,
      `Relatório de Simulação | ${processo.NOME} [${
        processo.CREATED_AT.split('T')[0]
      }]`,
      emailBody,
      emailccArchive,
    );
    changeTheme(savedTheme);
    setEmailArchive('');
    setEmailccArchive('');
    setLoading(false);
  }

  async function handleDownload() {
    setLoading(true);
    document.documentElement.className = 'light';
    const filename = `Relatório de Simulação [${processo.NOME}]_${
      processo.CREATED_AT.split('T')[0]
    }.pdf`;
    SCR.PDF.previewPDF('downloadProcessoReport', filename);
    changeTheme(savedTheme);
    setLoading(false);
  }

  useEffect(() => {
    document.documentElement.className = 'light';
    changeTheme('light');
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   console.log(canvas);
  //   if (canvas) {
  //     canvas.toBlob((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       setQrCodeUrl(url);
  //     });
  //   }
  // }, [canvasRef]);

  useEffect(() => {

    const get_processo = async () => {
      setLoading(true);
      try {
        const simulacao = await API(END.sim.read.codigo, {
          codigo,
        });
        if (simulacao.data) {
          const sim = simulacao.data[0];
          setProcesso({
            ...sim,
            tipo: sim.SIMULACAO_COMPLETA ? 'Completa' : 'Simplificada',

            data_criado: new Date(sim.CREATED_AT).toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            }),

            hora_criado: new Date(sim.CREATED_AT).toLocaleTimeString('pt-BR', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            }),

            NASCIMENTO: dateFormat(sim.NASCIMENTO),
            OUTRO_NASCIMENTO: sim.OUTRO_NASCIMENTO
              ? dateFormat(sim.OUTRO_NASCIMENTO)
              : null,
            MAIS_VELHO: dateFormat(sim.MAIS_VELHO),
            // COMPROVAR: stringToArray(sim.COMPROVAR),
            // OUTRO_COMPROVAR: stringToArray(sim.OUTRO_COMPROVAR),
          });
        }
      } catch (error) {
        console.error(error);
      }
      setLoaded(true);
      setLoading(false);
    };
    get_processo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codigo, END]);

  useEffect(() => {
    if (loaded && emailArchive) handlePDF(emailArchive);
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        setQrCodeUrl(url);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const dadosSimulacao = () => {
    return (
      <>
        <div className='my-0'>
          <div className='gorow justify-content-between'>
            <div className='gocol mt-5 justify-content-end align-items-start'>
              <h6 className='ffSoft mt-5 mb-0 fw600'>Relatório de Simulação</h6>
              <p className='fs10 textContrastLow mb-3'>
                Gerado em {new Date().toLocaleDateString('pt-BR')}
                {' às '}
                {new Date().toLocaleTimeString('pt-BR')}
              </p>

              <h4 className='textContrastColor mb-0'>{processo.NOME}</h4>
              <p className='fw500 fs12 mb-0'>{processo.CPF}</p>
              <p className='fw500 fs12 mb-0'>{processo.EMAIL}</p>
              <p className='fw500 fs12'>{processo.WHATSAPP}</p>
            </div>
            <div className='pb-3 gocol justify-content-end align-items-end'>
              <img
                src={CONF.images.logo}
                alt='logo'
                title='logo'
                width='250'
              />

              <img
                src={qrCodeUrl}
                alt='QR Code IMG'
                title='QR Code'
                width='125'
                className='mt-5'
              />

              <div data-html2canvas-ignore={true}>
                <QRCodeCanvas
                  className='divHide'
                  ref={canvasRef}
                  value={`https://app.${CONF.domain}/report/simulacao/${processo.PROCESSO_CODIGO}`}
                />
              </div>
            </div>
          </div>

          <div className='fs12 mt-5'>
            <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
              <span className='textContrastLow wp200'>
                {processo.IMOVEL_DESCRICAO}
              </span>
              <span>
                {numberFormat(processo.IMOVEL_VALOR)} ({processo.IMOVEL_CIDADE})
              </span>
            </p>
            <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
              <span className='textContrastLow wp200'>Corretor</span>
              <span>{`${
                processo.CORRETOR_NOME === null
                  ? 'Nenhum'
                  : processo.CORRETOR_NOME
              }`}</span>
              <span>
                {processo.IMOBILIARIA_NOME !== null &&
                processo.IMOBILIARIA_NOME !== '' &&
                processo.IMOBILIARIA_NOME !== 'Nenhuma'
                  ? ` | Imobiliária: ${processo.IMOBILIARIA_NOME}`
                  : ''}
              </span>
            </p>
          </div>
        </div>

        <div className='fs12 my-2'>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Tipo de Simulação</span>
            <span>{processo.tipo}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Data</span>
            <span>{`${processo.data_criado}  ${processo.hora_criado}`} </span>
          </p>
          <p className='ffSoft fw600 fs10 textContrastColor mt-3 mb-1 '>
            <br />
            Dados do Cliente
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Nome</span>
            <span className='wp250'>{processo.NOME}</span>
            {processo.COMPOR && processo.SIMULACAO_COMPLETA ? (
              <><br /><span className='wp250'>{processo.OUTRO_NOME}</span></>
            ) : null}
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>CPF</span>
            <span  className='wp250'>{processo.CPF}</span>
            {processo.COMPOR && processo.SIMULACAO_COMPLETA ? (
              <span className='wp250'>{processo.OUTRO_CPF}</span>
            ) : null}
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Nascimento</span>
            <span className='wp250'>{processo.NASCIMENTO}</span>
            {processo.COMPOR && processo.SIMULACAO_COMPLETA ? (
              <span className='wp250'>{processo.OUTRO_NASCIMENTO}</span>
            ) : null}
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Mais Velho</span>
            <span>{processo.MAIS_VELHO}</span>
          </p>

          {processo.COMPOR && processo.SIMULACAO_COMPLETA ? (
            <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
              <span className='textContrastLow wp200'>Relacionamento</span>
              <span></span>
              <span>{processo.RELACIONAMENTO}</span>
            </p>
          ) : null}
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>E-mail</span>
            <span>{processo.EMAIL}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>WhatsApp</span>
            <span>{processo.WHATSAPP}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Estado Civil</span>
            <span>{processo.ESTADO_CIVIL}</span>
          </p>

          <p className='ffSoft fw600 fs10 textContrastColor mt-3 mb-1 '>
            <br />
            Dados de Renda
          </p>

          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Renda Bruta</span>
            <span className='wp250'>{numberFormat(processo.RENDA)}</span>
            {processo.COMPOR && processo.SIMULACAO_COMPLETA ? (
              <span className='wp250'>{numberFormat(processo.OUTRO_RENDA)}</span>
            ) : null}
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>FGTS</span>
            <span className='wp250'>{numberFormat(processo.FGTS)}</span>
            {processo.COMPOR && processo.SIMULACAO_COMPLETA ? (
              <span className='wp250'>{numberFormat(processo.OUTRO_FGTS)}</span>
            ) : null}
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Outros Recursos</span>
            <span className='wp250'>{numberFormat(processo.RECURSOS)}</span>
            {processo.COMPOR && processo.SIMULACAO_COMPLETA ? (
              <span className='wp250'>{numberFormat(processo.OUTRO_RECURSOS)}</span>
            ) : null}
          </p>

          <p className='ffSoft fw600 fs10 textContrastColor mt-3 mb-1 '>
            <br />
            Dados do Imóvel
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Cidade</span>
            <span>{processo.IMOVEL_CIDADE}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Descrição</span>
            <span>{processo.IMOVEL_DESCRICAO}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Valor</span>
            <span>{numberFormat(processo.IMOVEL_VALOR)}</span>
          </p>

          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>
              Valor do Financiamento
            </span>
            <span>{numberFormat(processo.VALOR_FINANCIADO)}</span>
          </p>

          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Valor da Entrada</span>
            <span>{numberFormat(processo.VALOR_ENTRADA)}</span>
          </p>

          <p className='ffSoft fw600 fs10 textContrastColor mt-3 mb-1 '>
            <br />
            Outras Variáveis
          </p>

          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Foi Beneficiado</span>
            <span>{processo.BENEFICIADO ? 'Sim' : 'Não'}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Servidor Público</span>
            <span>{processo.SERVIDOR_PUBLICO ? 'Sim' : 'Não'}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Possui 3 anos de FGTS</span>
            <span>{processo.ANOS_FGTS ? 'Sim' : 'Não'}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Possui Dependentes</span>
            <span>{processo.DEPENDENTES ? 'Sim' : 'Não'}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>
              Atualmente Registrado CLT?
            </span>
            <span>{processo.CLT ? 'Sim' : 'Não'}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>
              Possui Imóvel Registrado?
            </span>
            <span>{processo.POSSUI_IMOVEL ? 'Sim' : 'Não'}</span>
            {processo.POSSUI_IMOVEL ? (
              <span>{processo.POSSUI_IMOVEL_CIDADE}</span>
            ) : null}
          </p>
          <br />
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Observações</span>
            <span>{processo.INFORMACOES}</span>
          </p>

          <p className='ffSoft fw600 fs10 textContrastColor mt-3 mb-1 '>
            <br />
            Atendimento
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Corretor</span>
            <span>{processo.CORRETOR_NOME}</span>
          </p>
          <p className='gorow align-items-center py-1 mb-0 border-bottom borGray200'>
            <span className='textContrastLow wp200'>Imobiliária</span>
            <span>{processo.IMOBILIARIA_NOME}</span>
          </p>
        </div>
      </>
    );
  };

  if (logged === null) {
    return <PKG.LoadingDotsLight conf={CONF} />;
  } else if (logged === false) {
    navigate('/logout');
  } else {
    if (loading) {
      return <PKG.LoadingDotsLight conf={CONF} />;
    }

    return (
      <PKG.ContainerBox
        main={
          <div className='w100 mb-5 p-2'>
            <div className='gorow'>
              <PKG.IconsUI
                info={PKG.ui.file.pdf}
                clsnm='svg14 cRed hovGreen mousePointerHover ms-2'
                canvasIgnore={true}
                click={() => {
                  Swal.fire({
                    title: 'Informe o e-mail para envio do relatório',
                    input: 'email',
                    inputPlaceholder: 'E-mail',
                    showCancelButton: true,
                    confirmButtonText: 'Enviar',
                    cancelButtonText: 'Cancelar',
                    background: theme === 'light' ? '#fff' : '#333',
                    iconColor: theme === 'light' ? '#aa0011' : '#aa0011c2',
                    customClass: {
                      title: 'fs24 mt-3 mb-2 fw600 textContrastColor',
                      confirmButton: 'btn btn-success',
                      cancelButton: 'btn btn-danger',
                      validationMessage: 'fs12 cRed bgContrastSoftDarker',
                      input: 'fs14 textContrast',
                    },
                    inputValidator: (value) => {
                      if (!SCR.Helpers.is_valid_email(value)) {
                        return 'E-mail inválido';
                      }
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handlePDF(result.value);
                    }
                  });
                }}
                sideText='Gerar PDF'
                sideTextClass='fs14 ms-2'
                sideDivClass='wp125 py-3 mousePointerHover divIconsUi hovGreen'
              />
              <PKG.IconsUI
                info={PKG.ui.action.download}
                clsnm='svg14 cLink hovGreen mousePointerHover ms-2'
                canvasIgnore={true}
                click={() => {
                  handleDownload();
                }}
                sideText='Baixar'
                sideTextClass='fs14 ms-2'
                sideDivClass='wp125 p-3 mousePointerHover divIconsUi hovGreen'
              />
            </div>
            <div className='my-5 divHide'>
              <iframe
                id='simulacao_report'
                width='100%'
                height='1000'
                title={`Simulação Report ${processo.PROCESSO_CODIGO}`}
              ></iframe>
            </div>
            <div
              className=''
              id='downloadProcessoReport'
            >
              {dadosSimulacao()}
            </div>
          </div>
        }
        footer={null}
        navbar={<NavBar />}
        overlayMain={false}
        classMain='container bgWhite'
        navreturn={true}
        navreturnClass='container'
      />
    );
  }
}
